export function formatPrice(input) {
    // Convert the input to a number if it's a string or integer
    const price = Number(input);

    // Check if the input is a valid number
    if (isNaN(price)) {
        return "Invalid input";
    }

    // Format the price with 3 digits after the decimal point
    const formattedPrice = price.toFixed(3);

    // Return the formatted price as a string
    return formattedPrice;
}
