<template>
    <div id="item-ledger-entry-filter">
        <b-row>
            <b-col cols="12" sm="6" class="text-left">
                <b-row>
                    <b-col cols="12" sm="6">
                        <label for="datepicker-invalid">Date Début</label>
                        <b-form-datepicker size="sm" v-model="startDate" placeholder="Date Début" id="datepicker-debut"
                            :max="new Date()" />
                    </b-col>
                    <b-col cols="12" sm="6">
                        <label for="datepicker-fin">Date Fin</label>
                        <b-form-datepicker size="sm" v-model="endDate" placeholder="Date Fin" id="datepicker-fin"
                            :max="new Date()" />
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" sm="6" class="text-right">
                <b-button size="sm" variant="success" class="mt-2"
                    @click="collapseVisible = false; $emit('filterClicked', { sDate: startDate, eDate: endDate })">
                    <feather-icon icon="FilterIcon" class="mr-50 ml-50" />
                    Filtrer
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            startDate: new Date().toISOString().split('T')[0],
            endDate: new Date().toISOString().split('T')[0],
        }
    }
}
</script>
<style lang="scss"></style>